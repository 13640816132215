import styled from '@emotion/styled'

export const AnimationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    height: 5%;
    padding: 30px 0;
    margin: 0 auto;
`
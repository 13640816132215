import theme from '../../styles/theme/theme'

import * as animationDataMobile0 from '../../images/animations/module-2/onb-localization-prev-narrow/onb-localization-prev-narrow-sm.json'
import * as animationDataMobile1 from '../../images/animations/module-2/onb-preloader-prev-narrow/onb-preloader-prev-narrow-sm.json'
import * as animationDataMobile2 from '../../images/animations/module-2/onb-change-password-prev-narrow/onb-change-password-prev-narrow-sm.json'
import * as animationDataMobile3 from '../../images/animations/module-2/onb-email-send-prev-narrow/onb-email-send-prev-narrow-sm.json'
import * as animationDataMobile4 from '../../images/animations/module-2/onb-no-results-prev-narrow/onb-no-results-prev-narrow-sm.json'

import * as animationData0 from '../../images/animations/module-2/onb-localization-prev-wide/onb-localization-prev-wide-sm.json'
import * as animationData1 from '../../images/animations/module-2/onb-preloader-prev-wide/onb-preloader-prev-wide-sm.json'
import * as animationData2 from '../../images/animations/module-2/onb-change-password-prev-wide/onb-change-password-prev-wide-sm.json'
import * as animationData3 from '../../images/animations/module-2/onb-email-send-prev-wide/onb-email-send-prev-wide-sm.json'
import * as animationData4 from '../../images/animations/module-2/onb-no-results-prev-wide/onb-no-results-prev-wide-sm.json'

import gallery1 from '../../images/png/gallery-1@2x.png'
import gallery2 from '../../images/png/gallery-2@2x.png'
import gallery3 from '../../images/png/gallery-3@2x.png'
import gallery4 from '../../images/png/gallery-4@2x.png'
import gallery6 from '../../images/png/gallery-6@2x.png'
import gallery7 from '../../images/png/gallery-7@2x.png'
import gallery8 from '../../images/png/gallery-8@2x.png'

import gif from '../../images/modul-5-animacje.gif'

import logoKlassenGeld from '../../images/svg/Klassengeld-logo-bcg.svg'
import klassengeldMobile from '../../images/png/m-klassengeld-modul-6-c@1x.png'
import klassengeldMobile2 from '../../images/png/m-klassengeld-modul-6-c@2x.png'
import klassengeld from '../../images/png/klassengeld-modul-6@1x.png'
import klassengeld2 from '../../images/png/klassengeld-modul-6@2x.png'

import monitor from '../../images/png/m-monitor-modul-7@2x.png'
import template from '../../images/png/template-lp-desktop-v2@2x.png'

import gziniankaLogo from '../../images/svg/Gzinianka-logo.svg'
import gziniankaBg from '../../images/png/Logotypes-bcg-modul-8@1x.png'
import gziniankaBgM from '../../images/png/m-Logotypes-bcg-modul-8@1x.png'

import delfortLogo from '../../images/svg/Delfort-logo-w.svg'
import delfortBg from '../../images/png/Logotypes-bcg-modul-8-gi-a@1.png'
import delfortBgM from '../../images/png/m-Logotypes-bcg-modul-8-gi-a@1.png'

import logoDeloitte from '../../images/svg/Deloitte-logo.svg'
import deloitteImg from '../../images/png/m-Deloitte-modul-9@1x.png'

import mockup1 from '../../images/png/mock-up-1@2x.png'
import mockup2 from '../../images/png/mock-up-2@2x.png'
import mockup3 from '../../images/png/mock-up-2-2@2x.png'

export const section1a = [
	animationDataMobile0,
	animationDataMobile1,
	animationDataMobile2,
	animationDataMobile3,
	animationDataMobile4,
]
export const section1b = [
	animationData0,
	animationData1,
	animationData2,
	animationData3,
	animationData4,
]
export const section2 = [
	{
		img: gallery1,
		alt: 'Apka Goodie - wybierz marki'
	},
	{
		img: gallery2,
		alt: 'Apka Goodie - rejestracja'
	},
	{
		img: gallery3,
		alt: 'Apka Goodie - loader'
	},
	{
		img: gallery4,
		alt: 'Apka Goodie - wybierz miasto'
	},
	{
		img: gallery6,
		alt: 'Apka Goodie - marki'
	},
	{
		img: gallery7,
		alt: 'Apka Goodie - udostępnij lokalizację'
	},
	{
		img: gallery8,
		alt: 'Apka Goodie - email wysłany'
	},
]
export const section4 = [
	{
		img: gif,
		alt: 'Apka Goodie - animacja brak wyników wyszukiwania'
	}
]
export const section5 = [
	{
		logo: logoKlassenGeld,
		mobile: klassengeldMobile,
		mobile2: klassengeldMobile2,
		bg: klassengeld,
		bg2: klassengeld2,
		alt: 'Otwarty komputer z aplikacją Klassengeld'
	}
]
export const section6 = [
	{
		left: monitor,
		leftAlt: 'Monitor z aplikacją Goodie',
		right: template,
		rightAlt: 'Goodie stona'
	}
]
export const section7 = [
	{
		img: gziniankaLogo,
		title: 'Gzinianka',
		subtitle: 'Green House',
		backgroundColor: 'green',
		background: gziniankaBg,
		backgroundM: gziniankaBgM,
	},
	{
		img: delfortLogo,
		title: 'Delfort Programm',
		subtitle: 'Goethe-Institut Polen',
		backgroundColor: theme.colors.background.delfort,
		background: delfortBg,
		backgroundM: delfortBgM,
	},
]
export const section8 = [
	{
		logo: logoDeloitte,
		img: deloitteImg,
		alt: 'Deloitte obrazek'
	}
]
export const section9 = [
	{
		title: 'Lorem ipsum',
		description:
			'dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		text: `Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
					dolore magna aliqua.Quis ipsum suspendisse ultrices gravida.Risus commodo viverra maecenas accumsan lacus vel facilisis.`,
		imgArray: [mockup1, mockup2, mockup3]
	},
]

import { css } from '@emotion/core';
import normalize from 'emotion-normalize';
import 'swiper/swiper-bundle.css';

import { text, arrow, background } from './theme/colors';

const base = css`
	* {
		border: none;
		box-sizing: border-box;
		font-family: 'Lato', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@fontface {
		font-family: 'Lato', sans-serif;
		src: url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
	}

	html {
		font-size: 62.5%; //== 10px (so that 1rem == 10px)
	}

	body {
		color: ${text.primary};
		font-family: 'Lato', sans-serif;
		font-size: 1.6rem;
		height: 100vh;
	}

	p,
	ul,
	li {
		margin: 0;
	}

	.swiper-container.swiper-container-initialized.swiper-container-horizontal {
		.swiper-button-prev,
		.swiper-button-next {
			display: none;
		}

		.swiper-pagination-bullet {
			background-color: ${background.mockup};
			opacity: 1;
		}

		.swiper-pagination-bullet-active {
			background-color: ${background.goodie};
		}

		@media screen and (min-width: 767px) {
			.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
				display: none;
			}

			.swiper-button-prev,
			.swiper-button-next {
				display: flex;
				color: ${arrow.white};
			}

			.swiper-button-disabled {
				color: ${arrow.inactive};
				opacity: 0.2;
			}
		}
	}
`;

export default css`
	${normalize}
	${base}
`;

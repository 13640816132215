import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import globalStyles from '../../styles/global'
import theme from '../../styles/theme/theme'

import { Root } from './App.shards'

import Intro from '../Intro/Intro'
import Content from '../Content/Content'
import Footer from '../Footer/Footer'

function App() {
  return (
    <>
      <Global styles={globalStyles} />
      <ThemeProvider theme={theme}>
        <Root>
          <Intro />
          <Content />
          <Footer />
        </Root>
      </ThemeProvider>
    </>
  );
}

export default App;

import styled from '@emotion/styled'

import belt1 from '../../images/svg/belt1.svg'
import belt2 from '../../images/svg/belt2.svg'
import belt3 from '../../images/svg/belt3.svg'
import phone from '../../images/png/phone-modul-1@2x.png';

export const IntoRoot = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 500px;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background.intro};
    background-image: url(${phone});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
    font-size: 1.6rem;
    padding-top: 7.2vh;

    @media screen and (min-width: 767px) {
        justify-content: center;
        background-size: 70%;
    }

    @media screen and (min-width: 992px) {
        font-size: 2.6rem;
    }

    @media screen and (min-width: 1201px) {
        background-size: 50%;
        background-position: bottom center;
    }

    @media screen and (min-width: 1701px) {
        background-size: 30%;
    }
`

const Belt = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 3.85vh;

    @media screen and (min-width: 992px) {
        padding-left: 150px;
    }
`

export const Belt1 = styled(Belt)`
    background-image: url(${belt1});
    height: 10.1vh;
    width: 30.95vh;
    margin-bottom: 2.6vh;

    @media screen and (min-width: 992px) {
        height: 146px;
        width: 487px;
    }
`

export const Belt2 = styled(Belt)`
    background-image: url(${belt2});
    height: 5.4vh;
    width: 28.9vh;
    margin-bottom: 5px;

    @media screen and (min-width: 992px) {
        height: 73px;
        width: 431px;
    }
`

export const Belt3 = styled(Belt)`
    background-image: url(${belt3});
    height: 5.4vh;
    width: 38.95vh;

    @media screen and (min-width: 992px) {
        height: 88px;
        width: 495px;
    }
`

export const Name = styled.p`
    font-weight: 700;
    margin-bottom: 1vh;

    @media screen and (min-width: 992px) {
        margin-bottom: 20px;
    }
`

export const Title = styled.p`
    font-weight: 300;
    line-height: 15.04px;
`

export const Text = styled.p`
    font-weight: 700;
`

export const PhotoWrapper = styled.section`
    height: 41vh;

    img {
        height: 100%;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        align-self: flex-end;
    }

    @media screen and (min-width: 992px) {
        height: 51vh;
    }
`
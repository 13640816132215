import styled from '@emotion/styled'

export const FooterRoot = styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: ${({ theme }) => theme.colors.background.footer};
    font-size: 1.4rem;
    font-weight: 700;
    padding: 15px 31px;

    @media screen and (min-width: 576px) {
        justify-content: center;
        font-size: 1.6rem;
    }
`

export const DataWrapper = styled.section`
    @media screen and (min-width: 576px) {
        display: flex;
    }
`

export const TextWrapper = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 5px;

        @media screen and (min-width: 767px) {
            display: flex;
            align-items: center;
            margin: 0 20px;
        }
    }

    @media screen and (min-width: 576px) {
        margin: 0 20px;

        &:not(:last-of-type) {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 12px;
                right: -20px;
                width: 2px;
                height: 15px;
                background-color: ${({ theme }) => theme.colors.background.mockup};
            }
        }
    }
`

export const SectionTitle = styled.p`
    font-size: 1.6rem;

    @media screen and (min-width: 576px) {
        margin-right: 20px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 2px;
            right: -20px;
            width: 2px;
            height: 15px;
            background-color: ${({ theme }) => theme.colors.background.mockup};
        }
    }
`

export const Title = styled.p`
    font-weight: 300;

    @media screen and (min-width: 767px) {
        margin-left: 10px;
    }
`

export const Text = styled.p`
    line-height: 17px;
`
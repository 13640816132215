const _white = '#FFF'
const _colorGrey89 = '#E2E2E2'
const _colorGrey86 = '#DCDCDC'
const _colorGrey27 = '#444444'
const _colorGrey16 = '#282828'
const _black = '#000000'

const _ceriseRed = '#DD2764'
const _chileanFire = '#FA7905'
const _luckyPoint = '#1B2670'
const _havelockBlue = '#4990E2'
const _luckyPoint2 = '#17276F'

export const background = {
  nameTag: _luckyPoint,
  intro: _havelockBlue,
  goodie: _ceriseRed,
  animacje: _luckyPoint2,
  klassenGeld: _chileanFire,
  deloitte: _colorGrey16,
  mockup: _white,
  footer: _colorGrey27,
  delfort: _colorGrey86
}

export const text = {
  primary: _white,
  secondary: _colorGrey27,
}

export const arrow = {
  white: _white,
  black: _black,
  inactive: _colorGrey89
}
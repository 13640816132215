import React, { lazy, Suspense } from 'react'
import LazyLoad from 'react-lazyload'
import Lottie from 'react-lottie'

import {
    section1a,
    section1b,
    section2,
    section4,
    section5,
    section6,
    section7,
    section8,
    section9,
} from './ContentData'
import { AnimationWrapper } from './Content.shards'
import * as loader from '../../images/animations/loader.json'
const ModuleAnimation = lazy(() => import('../ModuleAnimation/ModuleAnimation'))
const ModulePopup = lazy(() => import('../ModulePopup/ModulePopup'))
const ModuleScroll = lazy(() => import('../ModuleScroll/ModuleScroll'))
const ModuleWebsite = lazy(() => import('../ModuleWebsite/ModuleWebsite'))
const ModuleSplit = lazy(() => import('../ModuleSplit/ModuleSplit'))
const ModuleLogo = lazy(() => import('../ModuleLogo/ModuleLogo'))
const ModuleBiggerOnDesktop = lazy(() => import('../ModuleBiggerOnDesktop/ModuleBiggerOnDesktop'))
const ModuleHalfOnDesktop = lazy(() => import('../ModuleHalfOnDesktop/ModuleHalfOnDesktop'))
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}
const renderLoader = () => <LazyLoad height={500}>
    <AnimationWrapper><Lottie options={defaultOptions} /></AnimationWrapper>
</LazyLoad>

const Content = () => (
    <>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleAnimation
                    data={section1b}
                    dataMobile={section1a}
                    name='animationsA'
                    title='Animacje'
                    withAnimation
                />
            </LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModulePopup
                    data={section2}
                    name='gallery'
                    title='Galeria'
                />
            </LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}><ModuleWebsite /></LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleAnimation
                    data={section4}
                    name='animationsB'
                    title='Animacje'
                />
            </LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleBiggerOnDesktop
                    name='klassengeld'
                    data={section5}
                />
            </LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleHalfOnDesktop
                    name='www'
                    title='Strony www'
                    data={section6}
                />
            </LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}><ModuleLogo data={section7} /></LazyLoad>
        </Suspense>
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleScroll
                    name='deloitte'
                    data={section8}
                />
            </LazyLoad>
        </Suspense>
		
		{/*
        <Suspense fallback={renderLoader()}>
            <LazyLoad height={500}>
                <ModuleSplit
                    name='mockups'
                    title='Mock ups'
                    data={section9}
                />
            </LazyLoad>
        </Suspense>
		*/}
    </>
)

export default Content

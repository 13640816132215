import React from 'react'

import {
    FooterRoot,
    SectionTitle,
    DataWrapper,
    TextWrapper,
    Title,
    Text
} from './Footer.shards'

const Footer = () => <FooterRoot>
    <SectionTitle>Portfolio</SectionTitle>
    <DataWrapper>
        <TextWrapper>
            <Text>Iwona Łukomska</Text>
            <Title>UI/UX Designer</Title>
        </TextWrapper>
        <TextWrapper>
            <Text>+48 692 726 007</Text>
            <Text>iwlukomska@gmail.com</Text>
        </TextWrapper>
    </DataWrapper>
</FooterRoot>

export default Footer
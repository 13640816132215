import React from 'react'

import {
    IntoRoot,
    Belt1,
    Belt2,
    Belt3,
    Name,
    Title,
    Text
} from './Intro.shards'

const Intro = () => <IntoRoot>
    <Belt1>
        <Name>Iwona Łukomska</Name>
        <Title>UI/UX Designer</Title>
    </Belt1>
    <Belt2>
        <Text>+48 692 726 007</Text>
    </Belt2>
    <Belt3>
        <Text>iwlukomska@gmail.com</Text>
    </Belt3>
</IntoRoot>

export default Intro